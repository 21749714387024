import React from 'react';
import { device } from '../rules/device';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';

import octavianImage from '../img/landing_page-octavian.png';
import Portfolio from '../components/Portfolio';
import HomePreFooter from '../components/HomePreFooter';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const IntroBox = styled.div`
  width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 6rem 2rem;

    @media ${device.laptop} {
      width: 100%;
    }

    @media ${device.tablet} {
      width: 100%;
      flex-direction: column;
    }

    @media ${device.mobileL} {
      margin: 4rem 1rem;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ProTitles = styled.div`
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    font-size: 0.9rem;
    margin-bottom: 2rem;
`;

const Introduction = styled.div`
    font-weight: 700;
    font-size: 4.8rem;
    letter-spacing: -2px;
    line-height: 1.1;
    margin-bottom: 4rem;

    @media ${device.mobileL} {
      font-size: 3.6rem;
      margin-bottom: 2rem;
    }
`;

const IntroPicture = styled.img`
    max-width: 520px;
    max-height: 520px;
    border: none;

    @media ${device.tablet} {
      width: 100%;
      align-self: center;
    }
`;

const Go = styled(HashLink)`
  color: #fff;
  font-family: var(--inter);
  text-decoration: none;
  background-color: var(--accent-color);
  border-radius: 5px;
  padding: 1rem;
  font-weight: 700;
  font-size: 1.1rem;
  position: relative;
  transition: all 0.2s ease;

  &:hover {
    color: #fff;
    background-color: var(--main-color);
  }

  &::after {
    font-family: 'navIcons' !important;
    font-weight: 800;
    position: absolute;
    content: ' \\e903';
    right: 10px;
    top: 18px;
    color: var(--main);
  }

  &:hover::after {
    color: #fff;
  }

  @media ${device.tablet} { 
    margin-bottom: 2.8rem;

    &::after {
      content: ' \\e902';
      right: 12px;
    }
  }

  @media ${device.mobileL} {
    margin-bottom: 2rem;
    font-weight: 600;
  }
`;

function Home() {
  window.scrollTo(0, 0);
  return (
    <>
      <Container>
        <IntroBox>
          <TextContainer>
            <ProTitles>
              Graphic Design, Web Development, Photography
            </ProTitles>
            <Introduction>
              Hey there, <br></br>I'm Octavian.
            </Introduction>
            <Go smooth to="#portfolio">Explore my design portfolio</Go>
          </TextContainer>
          <IntroPicture src={octavianImage} alt="Octavian Ilie"/>
        </IntroBox>
      </Container>
      <Portfolio id="portfolio"/>
      <HomePreFooter/>
    </>
  );
}

export default Home;
