import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled from 'styled-components';

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';

import Hellow from './pages/design/Hellow';
import Play from './pages/design/Play';
import Pannor from './pages/design/Pannor';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: center;
  background-color: var(--page-background);
`;

function App() {
  return (
    <Router>
      <Container>
      <Header/>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/design/hellow" component={Hellow} />
          <Route path="/design/play" component={Play} />
          <Route path="/design/pannor" component={Pannor} />
          <Route path="*">
            <NotFound/>
          </Route>
        </Switch>
        <Footer/>
      </Container>
    </Router>
  );
}

export default App;
