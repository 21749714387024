import React from 'react';
import {
  Container,
  WrapperTitle,
  WrapperContent,
  WrapperInfo,
  ProjectTitle,
  TitleLeft,
  ProjectInfoContainer,
  ProjectInfoWrapper,
  ProjectInfoImages,
  InfoTextRight,
  InfoDataRight,
  CriteriaRow,
  DataCriteria,
  InfoImage,
  OneImageRow,
  Image,
  TwoImagesRow,
  HalfImage,
} from './Styling';
import DesignFooter from '../../components/DesignFooter';

import white from '../../img/pannor_color_on_white.png';
import black from '../../img/pannor_white_on_black.png';
import color from '../../img/pannor_white_on_color.png';

function Pannor() {
  window.scrollTo(0, 0);
  return (
    <Container>
      <WrapperTitle>
        <ProjectTitle>
          <TitleLeft>
            Pannor
          </TitleLeft>
        </ProjectTitle>
      </WrapperTitle>
      <WrapperInfo>
        <ProjectInfoContainer>
          <ProjectInfoWrapper>
            <InfoTextRight>
              A perfect combination of Graphic Design, Web Development and my endless passion for the telecommunications industry. This is what this project is all about, and that's how Pannor was born - from logo to a functional corporate website.
            </InfoTextRight>
            <InfoDataRight>
              <CriteriaRow>
                <DataCriteria>Date</DataCriteria>July 2020
              </CriteriaRow>
              <CriteriaRow>
                <DataCriteria>Location</DataCriteria>Amsterdam, NL
              </CriteriaRow>
              <CriteriaRow>
                <DataCriteria>ROLE</DataCriteria>Designer, Developer
              </CriteriaRow>
              <CriteriaRow>
                <DataCriteria>URL</DataCriteria>www.pannor.nl
              </CriteriaRow>
            </InfoDataRight>
          </ProjectInfoWrapper>
          <ProjectInfoImages>
            <InfoImage src={white} alt="Pannor logo on white background" />
            <InfoImage src={black} alt="Pannor logo on black background" />
            <InfoImage src={color} alt="Pannor logo on color background" />
          </ProjectInfoImages>
        </ProjectInfoContainer>
      </WrapperInfo>
      <WrapperContent>
        <DesignFooter/>
      </WrapperContent>
    </Container>
  );
}

export default Pannor;
