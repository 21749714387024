import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { device } from '../rules/device';

import me from '../img/me.JPG';
import design from '../img/undraw_Redesign_feedback_re_jvm0.svg';
import coder from '../img/undraw_hacker_mindset_gjwq.svg';
import photography from '../img/undraw_edit_photo_2m6o.svg';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const InfoWrapper = styled.div`
  width: 1000px;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 6rem 2rem 4rem 2rem;

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
    flex-direction: column;
  }

  @media ${device.mobileL} {
    margin: 2rem 1rem;
  }
`;

const Photo = styled.img`
  width: 400px;
  height: 400px;
  border: none;
  border-radius: 5px;
  margin-right: 2rem;

  @media ${device.tablet} {
    margin: 0;
    order: 2;
    width: 100%;
    height: auto;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.tablet} {
    margin-bottom: 1rem;
  }
`;

const InfoText = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
  letter-spacing: -0.5px;
  font-weight: 400;

  @media ${device.tablet} {
    margin-bottom: 3rem;
  }

  @media ${device.mobileL} {
    font-size: 1.2rem;
  }
`;

const Name = styled.div`
  font-weight: 700;
  font-size: 4rem;
  letter-spacing: -2px;
  line-height: 1;

  @media ${device.tablet} {
    margin-bottom: 1.8rem;
  }
  
  @media ${device.mobileL} {
    font-size: 2.8rem;
  }
`;

const ContactButton = styled(Link)`
  color: #fff;
  font-family: var(--inter);
  text-decoration: none;
  background-color: var(--accent-color);
  border-radius: 5px;
  padding: 1rem;
  font-weight: 700;
  font-size: 1.1rem;
  position: relative;
  transition: all 0.2s ease;

  &:hover {
    color: #fff;
    background-color: var(--main-color);
  }

  &::after {
    font-family: 'navIcons' !important;
    font-weight: 800;
    position: absolute;
    content: ' \\e903';
    right: 10px;
    top: 18px;
    color: var(--main);
  }

  &:hover::after {
    color: #fff;
  }

  @media ${device.tablet} { 
    margin-bottom: 2.8rem;
  }

  @media ${device.mobileL} {
    margin-bottom: 2rem;
    font-weight: 600;
  }
`;

const ContentBelow = styled.div`
  width: 100%;
  display: flex;
`;

const ContentBelowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 4rem 2rem;

  @media ${device.mobileL} {
    margin: 2rem 1rem;
  }
`;

const MiddleTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 3.4em;
  letter-spacing: -2px;
  line-height: 1.1;
  font-weight: 700;
  margin-bottom: 4rem;

  @media ${device.mobileL} {
    text-align: left;
    font-size: 2.8rem;
    justify-content: flex-start;
  }
`;

const CardContainer = styled.div`
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 4rem;

  @media ${device.mobileL} {
    margin-bottom: 0;
  }
`;

const Card = styled.div`
  width: 310px;
  height: auto;
  padding: 1rem;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  margin: 0 1rem 2rem 1rem;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;

  &:hover {
    border: 1px solid #999;
  }

  @media ${device.mobileL} {
    width: 100%;
    margin: 0 0 2rem 0;

    &:hover {
      border: 1px solid #eaeaea;
    }
  }
`;

const CardImage = styled.img`
  height: 144px;
  margin-bottom: 2rem;
  margin-top: 2rem;
`;

const CardTitle = styled.div`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: -1px;
`;

const CardText = styled.div`
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: -0.5px;
`;

const CardButton = styled(Link)`
  color: #fff;
  font-family: var(--inter);
  text-decoration: none;
  text-align: center;
  background-color: var(--accent-color);
  border-radius: 5px;
  padding: 1rem;
  font-weight: 700;
  font-size: 1.1rem;
  transition: all 0.2s ease;
  margin-top: 2rem;

  &:hover {
    color: #fff;
    background-color: var(--main-color);
  }

  @media ${device.mobileL} {
    font-weight: 600;
  }
`;

const CardButtonDisabled = styled.div`
  color: var(--main-color);
  font-family: var(--inter);
  text-decoration: none;
  text-align: center;
  background-color: #eaeaea;
  border-radius: 5px;
  padding: 1rem;
  font-weight: 700;
  font-size: 1.1rem;
  transition: all 0.2s ease;
  margin-top: 2rem;

  &:hover {
    cursor: not-allowed;
  }

  @media ${device.mobileL} {
    font-weight: 600;
  }
`;

function About() {
  window.scrollTo(0, 0);
  return (
    <>
      <Container>
        <InfoWrapper>
          <Photo src={me} alt="Octavian Ilie" />
          <InfoBox>
            <Name>
              Octavian Ilie
            </Name>
            <InfoText>
              I'm a passionate Designer and Front-End Engineer from Amsterdam, specializing in Branding, Graphic Design, Web Design/Development and Photography.
              <br/><br/>
              I strive for a clean, exquisite minimal design, with an extra eye for detail.
            </InfoText>
            <ContactButton to="/contact">Reach out</ContactButton>
          </InfoBox>
        </InfoWrapper>
      </Container>
      <ContentBelow>
        <ContentBelowWrapper>
          <MiddleTitle>
            Discover my work.
          </MiddleTitle>
          <CardContainer>
            <Card>
              <CardTitle>
                Graphic Design
              </CardTitle>
              <CardImage src={design} alt="" />
              <CardText>
                Delivering pixel perfection using Adobe Illustrator, InDesign and Photoshop.
              </CardText>
              <CardButton to="/">View my portfolio</CardButton>
            </Card>
            <Card>
              <CardTitle>
                Web Development
              </CardTitle>
              <CardImage src={coder} alt="" />
              <CardText>
                Designing and developing websites and apps using popular JavaScript frameworks.
              </CardText>
              <CardButton to={{ pathname: "https://github.com/octavian-ilie" }} target="_blank">Check my GitHub</CardButton>
            </Card>
            <Card>
              <CardTitle>
                Photography
              </CardTitle>
              <CardImage src={photography} alt="" />
              <CardText>
                Photoshooting and bringing life to great photographs using Adobe Lightroom.
              </CardText>
              <CardButtonDisabled>Coming soon</CardButtonDisabled>
            </Card>
          </CardContainer>
        </ContentBelowWrapper>
      </ContentBelow>
    </>
  );
}

export default About;
