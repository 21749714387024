import React from 'react';
import styled from 'styled-components';
import { device } from '../rules/device';
import Icon from '../components/Icon';

import chat from '../img/undraw_Messaging_re_pgx8.svg';
import social from '../img/undraw_Social_media_re_w12q.svg';

const Container = styled.div`
  width: 100%;
  display: flex;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.laptop} {
    padding: 0 2rem;
  }
  
  @media ${device.tablet} {
    padding: 0 2rem;
  }

  @media ${device.mobileL} {
    padding: 0 1rem;
  }
`;

const ContactInfo = styled.div`
  width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 6rem 2rem 4rem 2rem;

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
    flex-direction: column;
  }

  @media ${device.mobileL} {
    margin: 2rem 1rem;
  }
`;

const SocialMedia = styled.div`
  width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 6rem 2rem 4rem 2rem;

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
    flex-direction: column;
  }

  @media ${device.mobileL} {
    margin: 2rem 1rem;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${device.tablet} {
    margin-bottom: 1rem;
    align-items: stretch;
  }
`;

const TitleLeft = styled.div`
  font-weight: 700;
  font-size: 4rem;
  letter-spacing: -2px;
  line-height: 1;
  margin-bottom: 2rem;

  @media ${device.tablet} {
    margin-bottom: 1.8rem;
    font-size: 2.8rem;
  }
`;

const TitleRight = styled.div`
  font-weight: 700;
  font-size: 4rem;
  letter-spacing: -2px;
  line-height: 1;
  margin-bottom: 2rem;
  text-align: right;
  width: 100%;

  @media ${device.tablet} {
    margin-bottom: 1.8rem;
    font-size: 2.8rem;
    text-align: left;
  }
`;

const InfoTextRight = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
  letter-spacing: -0.5px;
  font-weight: 400;
  text-align: right;
  width: 100%;

  @media ${device.tablet} {
    margin-bottom: 3rem;
    text-align: left;
  }

  @media ${device.mobileL} {
    font-size: 1.2rem;
  }
`;

const InfoTextLeft = styled.p`
  font-size: 1.4rem;
  line-height: 1.4;
  letter-spacing: -0.5px;
  font-weight: 400;

  @media ${device.tablet} {
    margin-bottom: 3rem;
  }

  @media ${device.mobileL} {
    font-size: 1.2rem;
  }
`;

const PhotoLeft = styled.img`
  width: 400px;
  height: 400px;
  border: none;
  border-radius: 5px;
  margin-left: 2rem;
  flex-shrink: 0;

  @media ${device.tablet} {
    margin: 0;
    order: 2;
    height: 280px;
    width: 280px;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`;

const PhotoRight = styled.img`
  width: 400px;
  height: 400px;
  border: none;
  border-radius: 5px;
  margin-right: 2rem;
  flex-shrink: 0;

  @media ${device.tablet} {
    margin: 0;
    order: 2;
    height: 280px;
    width: 280px;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`;

const EmailButton = styled.a`
  color: #fff;
  font-family: var(--inter);
  text-decoration: none;
  background-color: var(--accent-color);
  border-radius: 5px;
  padding: 1rem 1.5rem 1rem 1rem;
  font-weight: 700;
  font-size: 1.1rem;
  transition: all 0.2s ease;
  margin-top: 2rem;
  display: flex;
  align-items: center;

  &:hover {
    color: #fff;
    background-color: var(--main-color);
  }

  @media ${device.tablet} { 
    margin-bottom: 2.8rem;
    margin-top: 0;
  }

  @media ${device.mobileL} {
    margin-bottom: 2rem;
    font-weight: 600;
  }
`;

const Svg = styled(Icon)`
  width: 30px;
  height: 30px;
  margin-right: 1rem;
  transition: all 0.2s ease-in-out;
`;

const EmailIcon = ({ className }) => ( 
  <Svg viewBox="0 0 24 24" className={className} strokeLinecap="round" strokeLinejoin="round" fill="none" stroke="currentColor" strokeWidth="2">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <line x1="10" y1="14" x2="21" y2="3" />
    <path d="M21 3l-6.5 18a0.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a0.55 .55 0 0 1 0 -1l18 -6.5" />
  </Svg>
);

const InstagramIcon = ({ className }) => ( 
  <Svg viewBox="0 0 24 24" className={className} strokeLinecap="round" strokeLinejoin="round" fill="none" stroke="currentColor" strokeWidth="2">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <rect x="4" y="4" width="16" height="16" rx="4" />
    <circle cx="12" cy="12" r="3" />
    <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
  </Svg>
);

const FacebookIcon = ({ className }) => ( 
  <Svg viewBox="0 0 24 24" className={className} strokeLinecap="round" strokeLinejoin="round" fill="none" stroke="currentColor" strokeWidth="2">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
  </Svg>
);

const LinkedInIcon = ({ className }) => ( 
  <Svg viewBox="0 0 24 24" className={className} strokeLinecap="round" strokeLinejoin="round" fill="none" stroke="currentColor" strokeWidth="2">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <rect x="4" y="4" width="16" height="16" rx="2" />
    <line x1="8" y1="11" x2="8" y2="16" />
    <line x1="8" y1="8" x2="8" y2="8.01" />
    <line x1="12" y1="16" x2="12" y2="11" />
    <path d="M16 16v-3a2 2 0 0 0 -4 0" />
  </Svg>
);

const TwitterIcon = ({ className }) => ( 
  <Svg viewBox="0 0 24 24" className={className} strokeLinecap="round" strokeLinejoin="round" fill="none" stroke="currentColor" strokeWidth="2">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <path d="M22 4.01c-1 .49 -1.98 .689 -3 .99c-1.121 -1.265 -2.783 -1.335 -4.38 -.737s-2.643 2.06 -2.62 3.737v1c-3.245 .083 -6.135 -1.395 -8 -4c0 0 -4.182 7.433 4 11c-1.872 1.247 -3.739 2.088 -6 2c3.308 1.803 6.913 2.423 10.034 1.517c3.58 -1.04 6.522 -3.723 7.651 -7.742a13.84 13.84 0 0 0 .497 -3.753c-.002 -.249 1.51 -2.772 1.818 -4.013z" />
  </Svg>
);

const SocialLinksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const InstagramButton = styled.a`
  color: #fff;
  font-family: var(--inter);
  text-decoration: none;
  background-color: #d62976;
  border-radius: 5px;
  padding: 1rem 1.5rem 1rem 1rem;
  font-weight: 700;
  font-size: 1.1rem;
  transition: all 0.2s ease;
  margin-top: 2rem;
  margin-left: 2rem;
  display: flex;
  align-items: center;

  &:hover {
    color: #fff;
    background-color: var(--main-color);
  }

  @media ${device.tablet} { 
    margin-bottom: 2.8rem;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }

  @media ${device.mobileL} {
    margin-bottom: 2rem;
    font-weight: 600;
  }
`;

const FacebookButton = styled.a`
  color: #fff;
  background-color: #4267B2;
  font-family: var(--inter);
  text-decoration: none;
  border-radius: 5px;
  padding: 1rem 1.5rem 1rem 1rem;
  font-weight: 700;
  font-size: 1.1rem;
  transition: all 0.2s ease;
  margin-top: 2rem;
  margin-left: 2rem;
  display: flex;
  align-items: center;

  &:hover {
    color: #fff;
    background-color: var(--main-color);
  }

  @media ${device.tablet} { 
    margin-bottom: 2.8rem;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }

  @media ${device.mobileL} {
    margin-bottom: 2rem;
    font-weight: 600;
  }
`;

const LinkedInButton = styled.a`
  color: #fff;
  background-color: #0072b1;
  font-family: var(--inter);
  text-decoration: none;
  border-radius: 5px;
  padding: 1rem 1.5rem 1rem 1rem;
  font-weight: 700;
  font-size: 1.1rem;
  transition: all 0.2s ease;
  margin-top: 2rem;
  margin-left: 2rem;
  display: flex;
  align-items: center;

  &:hover {
    color: #fff;
    background-color: var(--main-color);
  }

  @media ${device.tablet} { 
    margin-bottom: 2.8rem;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }

  @media ${device.mobileL} {
    margin-bottom: 2rem;
    font-weight: 600;
  }
`;

const TwitterButton = styled.a`
  color: #fff;
  background-color: #00acee;
  font-family: var(--inter);
  text-decoration: none;
  border-radius: 5px;
  padding: 1rem 1.5rem 1rem 1rem;
  font-weight: 700;
  font-size: 1.1rem;
  transition: all 0.2s ease;
  margin-top: 2rem;
  margin-left: 2rem;
  display: flex;
  align-items: center;

  &:hover {
    color: #fff;
    background-color: var(--main-color);
  }

  @media ${device.tablet} { 
    margin-bottom: 2.8rem;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }

  @media ${device.mobileL} {
    margin-bottom: 2rem;
    font-weight: 600;
  }
`;

function Contact() {
  window.scrollTo(0, 0);
  return (
    <Container>
      <Wrapper>
        <ContactInfo>
          <InfoBox>
            <TitleLeft>
              Let's talk.
            </TitleLeft>
            <InfoTextLeft>
              If you'd like to discuss ideas, projects, business proposals or for any other inquiries, please send an email to the following address. 
              <br></br>
              <br></br>
              I'll reply as soon as possible.
            </InfoTextLeft>
            <EmailButton href="mailto:me@octavian.nl">
              <EmailIcon/>
              me@octavian.nl
            </EmailButton>
          </InfoBox>
          <PhotoLeft src={chat} alt="" />
        </ContactInfo>
        <SocialMedia>
          <PhotoRight src={social} alt="" />
          <InfoBox>
            <TitleRight>
              Let's connect.
            </TitleRight>
            <InfoTextRight>
              You may also follow me on social media platforms.
            </InfoTextRight>
            <SocialLinksContainer>
              <InstagramButton href="https://www.instagram.com/octavian.nl" target="_blank">
                <InstagramIcon/>
                octavian.nl
              </InstagramButton>
              <FacebookButton href="https://www.facebook.com/octavian.me" target="_blank">
                <FacebookIcon/>
                octavian.me
              </FacebookButton>
              <LinkedInButton href="https://www.linkedin.com/in/octavianilie" target="_blank">
                <LinkedInIcon/>
                octavianilie
              </LinkedInButton>
              <TwitterButton href="https://www.twitter.com/octavjan" target="_blank">
                <TwitterIcon/>
                octavjan
              </TwitterButton>
            </SocialLinksContainer>
          </InfoBox>
        </SocialMedia>
      </Wrapper>
    </Container>
  );
}

export default Contact;
