import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { device } from '../../rules/device';
import Icon from '../../components/Icon';

import linkIcon from '../../img/link.svg';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--page-background);
`;

const WrapperTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.laptop} {
    padding: 0 2rem;
  }
  
  @media ${device.tablet} {
    padding: 0 2rem;
  }

  @media ${device.mobileL} {
    padding: 0 1rem;
  }
`;

const WrapperInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-color);

  @media ${device.laptop} {
    padding: 0 2rem;
  }
  
  @media ${device.tablet} {
    padding: 0 2rem;
  }

  @media ${device.mobileL} {
    padding: 0 1rem;
  }
`;

const WrapperContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6rem 0;

  @media ${device.laptop} {
    padding: 0 2rem;
  }
  
  @media ${device.tablet} {
    padding: 0 2rem;
  }
  
  @media ${device.mobileL} {
    margin: 3rem 0;
    padding: 0 1rem;
  }
`;

// TO LOOK INTO: title in the middle on desktop, add navigation arrows < >

const ProjectTitle = styled.div`
  width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 6rem 2rem 2rem 2rem;
  
  @media ${device.laptop} {
    width: 100%;
  }
  
  @media ${device.tablet} {
    width: 100%;
    flex-direction: column;
  }
  
  @media ${device.mobileL} {
    margin: 2rem 1rem;
  }
`;
  
const TitleLeft = styled.div`
  font-weight: 700;
  font-size: 4rem;
  letter-spacing: -2px;
  line-height: 1;
  margin-bottom: 2rem;

  @media ${device.tablet} {
    margin-bottom: 1rem;
    font-size: 2.8rem;
  }
`;

const ProjectInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 2rem;

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
    flex-direction: column;
  }

  @media ${device.mobileL} {
    margin: 0 1rem;
  }
`;

const ProjectInfoWrapper = styled.div`
  width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
    flex-direction: column;
  }

  @media ${device.mobileL} {
    margin: 0 1rem;
  }
`;

const ProjectInfoImages = styled.div`
  width: 1000px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3rem;

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.tablet} {
    width: 100%;
    flex-direction: row;
  }

  @media ${device.mobileL} {
    flex-direction: row;
    align-items: flex-start;
    margin: 0 1rem 1rem 1rem;;
  }
`;

const InfoTextRight = styled.div`
  font-size: 1.4rem;
  line-height: 1.4;
  letter-spacing: -0.5px;
  font-weight: 400;
  width: 100%;
  margin: 2rem 0;

  @media ${device.tablet} {
    text-align: left;
  }

  @media ${device.mobileL} {
    font-size: 1.2rem;
  }
`;

const InfoDataRight = styled.div`
  width: 400px;
  margin-left: 2rem;
  padding: 2.2rem 0;
  font-size: 1rem;
  color: #888;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    width: 100%;
    margin-bottom: 2rem;
    margin-left: 0;
    padding: 0;
  }
`;

const CriteriaRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 0.6rem;
`;

const DataCriteria = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 1rem;
`;

const InfoImage = styled.img`
  width: 30%;
  height: auto;
  border-radius: 5px;

  @media ${device.mobileL} {
    width: 30%;
    margin-bottom: 2rem;
  }
`;

const OneImageRow = styled.div`
  width: 1000px;
  margin-bottom: 2rem;
  
  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.mobileL} {
    margin-bottom: 1rem;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 5px;
`;

const TwoImagesRow = styled.div`
  width: 1000px;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${device.laptop} {
    width: 100%;
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const HalfImage = styled.img`
  width: 48%;
  height: auto;
  border-radius: 5px;

  @media ${device.laptop} {
    width: 100%;
    margin-bottom: 2rem;
  }

  @media ${device.mobileL} {
    margin-bottom: 1rem;
  }
`;

const EndContainer = styled.div`
  width: 1000px;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;

  @media ${device.laptop} {
    width: 100%;
  }

  @media ${device.tablet} {
    margin-bottom: 0;
  }

  @media ${device.mobileL} {
    margin-bottom: 1rem;
  }
`;

const EndCaption = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 3.4em;
  letter-spacing: -2px;
  line-height: 1.1;
  font-weight: 700;
  margin: 4rem 0 4rem 0;
  text-align: center;

  @media ${device.tablet} {
    margin: 3rem 0 4rem 0;
  }

  @media ${device.mobileL} {
    text-align: left;
    font-size: 2.8rem;
    justify-content: flex-start;
    text-align: left;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const Svg = styled(Icon)`
  width: 30px;
  height: 30px;
  margin-right: 1rem;
`;

const BackIcon = ({ className }) => ( 
  <Svg viewBox="0 0 24 24" className={className} strokeLinecap="round" strokeLinejoin="round" fill="none" stroke="currentColor" strokeWidth="2">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <polyline points="15 6 9 12 15 18" />
  </Svg>
);

const MailIcon = ({ className }) => ( 
  <Svg viewBox="0 0 24 24" className={className} strokeLinecap="round" strokeLinejoin="round" fill="none" stroke="currentColor" strokeWidth="2">
    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <rect x="3" y="5" width="18" height="14" rx="2" />
    <polyline points="3 7 12 13 21 7" />
  </Svg>
);

const BackButton = styled(Link)`
  color: var(--accent-color);
  font-family: var(--inter);
  text-decoration: none;
  border: 2px solid var(--accent-color);
  border-radius: 5px;
  padding: 0.9rem 1.5rem 0.9rem 1rem;
  font-weight: 700;
  font-size: 1.1rem;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  margin: 0 1rem;

  &:hover {
    color: var(--main-color);
    border: 2px solid var(--main-color);
  }

  @media ${device.tablet} { 
    margin: 0 0 2rem 0;
  }

  @media ${device.mobileL} {
    flex-grow: 1;
    margin-bottom: 1rem;
    font-weight: 600;
  }
`;

const ContactButton = styled(Link)`
  color: #fff;
  font-family: var(--inter);
  text-decoration: none;
  background-color: var(--accent-color);
  border-radius: 5px;
  padding: 1rem 1.5rem 1rem 1rem;
  font-weight: 700;
  font-size: 1.1rem;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  margin: 0 1rem;

  &:hover {
    color: #fff;
    background-color: var(--main-color);
  }

  @media ${device.tablet} { 
    margin: 0 0 2rem 0;
  }

  @media ${device.mobileL} {
    flex-grow: 1;
    margin-bottom: 1rem;
    font-weight: 600;
  }
`;

const InlineLink = styled(Link)`
  text-decoration: none;
  color: var(--accent-color);
  transition: all 0.2s ease;
  position: relative;
  margin-left: 1.4rem;
  display: inline-block;

  &::before {
    content: url(${linkIcon});
    position: absolute;
    left: -1.4rem;
    top: 1px;
  }

  &::after {
    content: '';
    width: 0px;
    height: 1px;
    display: block;
    background-color: var(--main-color);
    transition: 300ms;
  }

  &:hover {
    color: var(--main-color);
    text-decoration: none;
    transition: all 0.2s ease-in-out;
  }

  &:hover::after {
    width: 100%;
  }
`;

export {
  Container,
  WrapperTitle,
  WrapperContent,
  WrapperInfo,
  ProjectTitle,
  TitleLeft,
  ProjectInfoContainer,
  ProjectInfoWrapper,
  ProjectInfoImages,
  InfoTextRight,
  InfoDataRight,
  CriteriaRow,
  DataCriteria,
  InfoImage,
  OneImageRow,
  Image,
  TwoImagesRow,
  HalfImage,
  EndContainer,
  EndCaption,
  BackIcon,
  BackButton,
  ButtonContainer,
  MailIcon,
  ContactButton,
  InlineLink,
};
